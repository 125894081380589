import Icon from "@mdi/react";
import {useForm} from "react-hook-form";
import styled from "styled-components";
import Overlay from "../common/components/overlay";
import {mdiArrowRight} from "@mdi/js";
import {yupResolver} from '@hookform/resolvers/yup';
import {ICustomer, useAddCustomerMutation, useGetCustomersQuery, useUpdateCustomerMutation} from "../redux/services/customer";
import * as yup from "yup";
import {useLocation, useNavigate} from "react-router-dom";
import Toast, {ToastType} from "../common/components/toast";
import {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

const StyledContent = styled.div `
	padding: 24px;
	min-width: 400px;	
	background: white;
	z-index: 102;
	top: 0;
	right: 0;
	height: 100vh;
	position: absolute;	
	font-size: 14px;
	.customer-breadcrumb {
		display: flex;
		align-content: center;		
		color: #6B778C;
		font-size: 15px;
		line-height: 17px;
		span {
			padding-left: 16px;
		}
		padding-bottom: 40px;
		:hover {
			cursor: pointer;
		}
	}
	.customer-title {
		color: #232323;
		font-weight: 800;
		font-size: 22px;
		line-height: 28px;
		padding-bottom: 40px;
	}
	.form-text-customer {
		display: flex;
		flex-direction: column;
		font-weight: 600;
		line-height: 20px;
		color: #000000;
		span {
			color: #BD0D22;
			font-weight: 400;	
		}
		input,
		textarea {
			height: 32px;
			text-indent: 8px;	
			&.customer-error-input {
				border: 1px solid #BD0D22;
			}					
			&:focus {				
    			border:3px solid #521DC7;    			
				outline: 0;
			}
		}
		textarea {
			height: auto;
			text-indent: 0;
			padding: 4px 8px;
		}
		padding-bottom: 24px;
		p {
			font-weight: 400;
			line-height: 16px;
			color: #BD0D22;
		}
	}
    .btn-primary.custom-btn {
		margin-top: 16px;
	    background-color: #521DC7;
	    border-color: #521DC7;
        color: #FFFFFF;
		font-weight: 600;
		line-height: 19px;		
    }    
`

type FormData = {
	customerName: string;
  	customerDescription: string;
	customerNumber: number;
	uuid: string;
};

function UpdateCustomer() {
	const {pathname} = useLocation();    
	const [opType, ] = pathname.split("/").reverse();

	const {data: customers} = useGetCustomersQuery();
	const actionMenu = useSelector((state: RootState) => state.contextMenu.actionMenu);    
	const [newCustomer, setNewCustomer] = useState<ICustomer|undefined|null>(undefined);

	const schema = yup.object({
		customerName: yup.string().required("This field is required.")
			.max(100, "Max length is 100 characters."),
			// .test("customerName-unique", "Customer name must be unique.",
			// 	(value) => !customers?.some((item: ICustomer) => item.customerName.toUpperCase() === value?.toUpperCase()) || false),				
		customerDescription: yup.string().max(255, "Max length is 255 characters."),
		customerNumber: yup.number()		
			.required("This field is required.")
			.max(99999, "Max length is 5 characters.")
			.typeError("You must specify a number")
			.test("customerNumber-unique", "Customer number must be unique.",
				(value) => {
					const notExisting = !customers?.some((item: ICustomer) => item.customerNumber.toString() === value?.toString());
					if (notExisting) return true;
					switch (opType) {
						case "create":
							return false;
						case "edit":
							const selectedCustomer = customers?.find((item: ICustomer) => item.customerNumber.toString() === value?.toString());
							if (selectedCustomer?.uuid === actionMenu.uuid) {
								return true;
							}
							return false;							
					}
					return false;
				}),
	}).required();  

	let currentCustomer: ICustomer | undefined;
	if (actionMenu.uuid !== undefined && opType === "edit") {
		currentCustomer = customers?.find((item: ICustomer) => item.uuid === actionMenu.uuid);
	} 
		
	const {register, handleSubmit, getValues, formState: {errors}} = useForm<FormData>({
		defaultValues: currentCustomer,
		resolver: yupResolver(schema), mode: "onBlur"
	});
	const [addCustomer, addMutation] = useAddCustomerMutation();
	const [updateCustomer, updateMutation] = useUpdateCustomerMutation();
	const navigate = useNavigate();	

  	const onSubmit = handleSubmit(submitData => {
		const handleAddCustomer = async (data: FormData) => {
			try {
				const customer = {...data};
				if (opType === "create") {
					const createdCustomer = await addCustomer(customer).unwrap();		  
					setNewCustomer(createdCustomer);
				}
				else if (opType === "edit") {
					const updatedCustomer = await updateCustomer(customer).unwrap();		  
					setNewCustomer(updatedCustomer);
				}
			} catch {
				setNewCustomer(null);
			}
		}		  	
		handleAddCustomer(submitData);
	});

	const onClose = () => {
		navigate("..")
	}
  	
	const getTitle = () => {		
		switch (opType.toLowerCase()) {
			case "create":
				return "Create Customer";
			case "edit":
				return "Edit Customer";				
		}
		return `Create Customer`;		
	}

	return (
		<>
			<Overlay />
			{((addMutation.isUninitialized && opType === "create") || (updateMutation.isUninitialized && opType === "edit")) && <StyledContent>		
				<div className="customer-breadcrumb" onClick={onClose}>
					<Icon path={mdiArrowRight} size={0.8} color="#6B778C" />
					<span>Close</span>
				</div>
				<div className="customer-title">
					{getTitle()}
				</div>				
        		<form onSubmit={onSubmit}>
					<div className="form-text-customer">
						<label>Customer Name<span>&nbsp;*</span></label>
						<input {...register("customerName")} className={errors.customerName && "customer-error-input"} />
						{ errors.customerName && <span>{errors.customerName?.message}</span> }
					</div>
					<div className="form-text-customer">
						<label>Customer Description</label>
						<textarea rows={8} {...register("customerDescription")} className={errors.customerNumber && "customer-error-input"} />
						{ errors.customerDescription && <span>{errors.customerDescription?.message}</span> }
					</div>
					<div className="form-text-customer">
						<label>Client Number<span>&nbsp;*</span></label>
						<input {...register("customerNumber")} className={errors.customerNumber && "customer-error-input"} />
						{ errors.customerNumber && <span>{errors.customerNumber?.message}</span> }
					</div>
					{ opType === "edit" && <div className="form-text-customer">
						<label>Customer ID<span>&nbsp;*</span></label>
						<input {...register("uuid")} readOnly />						
					</div>	}
					<input type="submit" value={`${getTitle()} Account`} className="btn btn-primary custom-btn" />
        		</form>
		    </StyledContent>}
            {((addMutation.isSuccess && opType === "create") || (updateMutation.isSuccess && opType === "edit")) && <Toast open={true} type={ToastType.AlertSuccess} 
				onClose={onClose} title={`Customer Account Successfully ${opType === "create" ? "Created" : "Updated"}`}>
                <div>
                    The Customer Account for {newCustomer?.customerName} has successfully been {opType === "create" ? "created" : "updated"}.
                </div>                
            </Toast>}
            {((addMutation.isError && opType === "create") || (updateMutation.isError && opType === "edit")) && <Toast open={true} type={ToastType.AlertError} 
				onClose={onClose} title={`Failed To ${opType === "create" ? "Create" : "Update"} Customer Account`}>
                <div>
                    Failed to {opType === "create" ? "create" : "update"} Customer Account for { getValues("customerName") }.
                </div>                
            </Toast>}
		</>		
  );
}

export default UpdateCustomer;
