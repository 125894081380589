declare var fullPath: any;
declare var appPath: any;
declare var baseUrl: any;


const ExternalVarsUtil = {

    getFullSubPath(): string {
        return fullPath?.replace(/^\/+/, '/').replace(/\/+$/, '') ?? '';
    },

    getAppPath(): string {
        return appPath?.replace(/^\/+/, '/').replace(/\/+$/, '') ?? '';
    },

    getAppBaseUrl(): string {
        return baseUrl?.replace(/\/+$/, '') ?? '';
    },

    getAppFullUrl(): string {
        return baseUrl?.replace(/\/+$/, '') + appPath?.replace(/^\/+/, '/').replace(/\/+$/, '') ?? '';
    },

}

export default ExternalVarsUtil;