import {Outlet} from "react-router-dom";
import styled from "styled-components";
import LeftNavigation from "../common/components/left-navigation";
import {useWhoAmIQuery} from "../redux/services/auth";
import {useGetUsersCountQuery} from "../redux/services/user-administration";
import UserTable from "./user-table";

const StyledContent = styled.div `
    background: #F0EDF6;
    display: flex;
    height: calc(100vh - 56px);
    > .main-content {
        width: 100%;
        height: 100%;
        .main-content-title {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            padding-top: 34px;
            padding-bottom: 16px;
        }
    }    
    b {
        padding: 30px;        
    }    
`

function UserAdministration() 
{   
    const {isLoading, data} = useGetUsersCountQuery();    
    const whoAmI = useWhoAmIQuery();
    
    if (isLoading || whoAmI.isLoading) {
        return <>"Loading...";</>        
    }

    if (whoAmI.data?.isAdmin && data) {
        return (
            <StyledContent>
                <LeftNavigation />
                <div className="main-content">
                    <div className="main-content-title">
                        Home / User Administration
                    </div>
                    <UserTable usersCount={data ?? 0} />
                </div>            
                <Outlet />            
            </StyledContent>
        );
    }
    if (whoAmI.data?.isAdmin === false) {
        return <StyledContent><b>Forbidden</b></StyledContent>
    }
    return <StyledContent>Error....</StyledContent>;        
}

export default UserAdministration;