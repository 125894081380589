import styled from "styled-components";
import rldatix from "../images/rldatix-logo.svg";
import admin from "../images/admin.png";
import onesource from "../images/one-source.png";
import peersupport from "../images/peer-support.svg";
import {useNavigate} from "react-router-dom";
import {ILandingPageApp, useGetLandingPageAppsQuery} from "../redux/services/landing-page-app";
import {useWhoAmIQuery} from "../redux/services/auth";

const StyledContent = styled.div `
    font-weight: 600;
    font-size: 22px;
    line-height: 18px;
    background: #F2F2F2;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .landing-page-instructions {
        font-size: 28px;
        padding-top: 64px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 0px;
    }
    img.rldatix-logo {
        width: 400px;
    }
    .application-logo {
        display: flex;
        > a {
            color: black;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #F2F2F2;
            margin: 112px 98px;
            label {
                padding-top: 16px;
            }
            > div {
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0523656), 0px 10px 20px rgba(169, 170, 172, 0.15);
                border-radius: 14px;
                background: white;
                padding: 25px;
                
                img {
                    width: 165px;
                    height: 165px;
                }
            }
        }
    }
`

function LandingPage()
{
    const navigate = useNavigate();    
    const {isLoading, data} = useGetLandingPageAppsQuery();
    const whoAmI = useWhoAmIQuery();

    const onClick = (item: ILandingPageApp) => {
        const {url, isExternal} = item;
        if (isExternal) {
            window.location.href = item.url
        }
        else {
            navigate(url);
        }        
    }

    if (isLoading || whoAmI.isLoading) {
        return <>Loading....</>;
    }

    const showImage = (name: string) => {
        switch (name) {
            case "OneSource":
                return <img src={onesource} alt="OneSource Logo"/>;
            case "Peer Support":
                return <img src={peersupport} alt="Peer Support Logo"/>;
            case "Admin":
                return <img src={admin} alt="Admin Logo"/>
        }
    }

    return (
        <StyledContent>
            <img className="rldatix-logo" src={rldatix} alt="RLDatix Logo" />
            <main>
                <h1 className="landing-page-instructions">
                    Please select the required service below
                </h1>
                <div className="application-logo">
                    {data?.filter((i: ILandingPageApp) => whoAmI?.data?.isAdmin === true || i.adminOnly === false)
                        .map((item: ILandingPageApp, index: number) => 
                        <a key={index} href="#" onClick={() => onClick(item)}>
                            <div>
                                {showImage(item.name)}
                            </div>
                            <label>{item.name}</label>
                        </a>
                    )}
                </div>
            </main>
        </StyledContent>    
    );
}

export default LandingPage;