import React, { useState } from "react";
import styled from "styled-components";
import {useGetUsersQuery} from "../redux/services/user-administration";
import TableLayout from "../common/components/table-layout";
import {IUser} from "../redux/services/user-administration";
import {DateTime} from "luxon";
import Icon from "@mdi/react";
import {mdiDotsVertical, mdiAccountMultiplePlus} from "@mdi/js";
import {useNavigate} from "react-router-dom";
import Pagination, {PaginationType} from "../common/components/pagination";

const StyledContent = styled.div `
    margin-right: 58px;
    padding: 28px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 80px);
    .user-content-table
    {
        height: calc(100% - 120px);
        overflow: auto;        
    }

    .btn-primary.custom-btn {
	    background-color: #C3CDF6;
	    border-color: #C3CDF6;
        color: #1B2E7A;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;        
        span {
            padding-right: 8px;
        }
    }    

    .user-table-title {
        display: flex;
        height: 40px;
        margin-bottom: 20px;
        justify-content: space-between;
        font-size: 22px;
        font-weight: 800;
        line-height: 28px;
    }

    table {
        width: 100%;
        border-spacing: 0;
        border-radius: 2px;
        border: 1px solid #DBDBDB;

        tr {
            :last-child {
                td {
                    border-bottom: 0;                    
                }
            }
        }
        th,
        td {
            :last-child {
                text-align: center;
            }
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            height: 72px;    
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #DBDBDB;
        }

        th {
            font-size: 14px;    
            font-weight: 600;
            line-height: 9px;
        }
    }
`

export interface IUserTableProps {
    usersCount: number;
}

function UserTable(props: IUserTableProps) {

    const {usersCount} = props;

    const columns = React.useMemo(() => [
        {
            Header: "FIRST NAME",
            accessor: "firstName",
        },
        {
            Header: "LAST NAME",
            accessor: "lastName",
        },
        {
            Header: "EMAIL",
            accessor: "email",
        },
        {
            Header: "CUSTOMER",
            accessor: "customerName",
        },
        {
            Header: "IS ADMIN",
            accessor: (item: IUser) => item.isAdmin ? "True" : "False"
        },
        {
            Header: "UPDATED",
            accessor: (item: IUser) => DateTime.fromISO(item.updatedDate).toLocaleString(DateTime.DATETIME_SHORT)
        },
        {
            Header: "ACTIONS",
            Cell: (props: any) => (
                <Icon path={mdiDotsVertical} size={1} color="#6D6D6D" />
              ),
        },                
      ], []);

    const initialState: PaginationType = {
        pageSize: 10,
        selectedPage: 1,
        itemCount: usersCount
    }     

    // Using a query hook automatically fetches data and returns query values
    const [pagination, setPagination] = useState<PaginationType>(initialState);
    const {isLoading, error, data} = useGetUsersQuery(pagination);
    const navigate = useNavigate();    

    const paginationProps = {
        pagination,
        setPagination
    };

    if (isLoading) {
        return <>
            "Loading...";
        </>        
    }

    if (error) {
        if (error instanceof Error) {
            return <>
                {error.message}
            </>
        }        
    }

    return (
        <StyledContent>          
            <div className="user-table-title">
                <div>
                    User Administration 
                </div>
            </div> 
            <div className="user-content-table">
                <TableLayout columns={columns} data={data} />            
            </div>                       
            <Pagination {...paginationProps} />
        </StyledContent>
    )
}
  
export default UserTable;
  