import React, {useState} from "react";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import {useLoginUserInternalMutation, useLoginUserOneSourceMutation, useWhoAmIQuery} from "../redux/services/auth";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {RootState} from "../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {updateLoginUser} from "../redux/slices/login-user";
import loading from "../images/loading.gif";
import {useGetLandingPageAppsQuery} from "../redux/services/landing-page-app";
import LoginTooltip from "./login-tooltip";

const StyledContent = styled.form `
    display: flex;
    flex-direction: column;
    .error {
        color: #EE0000;
    }
    .login-form-text {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        label {
            line-height: 20px;                        
            color: #232323;
        }
        input {
            border: 1px solid #6D6D6D;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 4px;
            text-indent: 8px;
            height: 32px;
            width: 360px;
            &:focus {				
                border:3px solid #094367;    			
                outline: 0;
            }                        
			&.email-password-error-input {
				border: 1px solid #BD0D22;
			}					
        }
        span {
			color: #BD0D22;
			font-weight: 400;	
		}
    }               
    .btn-primary.custom-btn {
        background-color: #094367;
        border-color: #094367;
        color: #FFFFFF;
        font-weight: 400;
        line-height: 19px;		
        width: 124px;            
        margin-top: 8px;
        :focus {
            box-shadow: 0 0 0 0.25rem #4C94FE;
        }
    }       
    .forgot-password {
        padding-top: 12px;
        a {
            text-decoration: none;
        }
    }             
    &.loading-screen {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 800;    
        font-size: 16px;    
        > div {            
            display: flex;
            flex-direction: column;        
            img {
                height: inherit;
                padding-top: 20px;
            }
        }
    }
`

type FormData = {
	username: string;
    password: string;
};

function EmailPassword()
{
    // whoAmI is required to trigger a whoAmI call on onesourcelogin
    const whoAmI = useWhoAmIQuery();  
    const [isOneSourceLoginFailed, setIsOneSourceLoginFailed] = useState(false);
    const landingPageApps = useGetLandingPageAppsQuery(); 
    const location: any = useLocation();   
    const dispatch = useDispatch();       
    let userLoggingIn = useSelector((state: RootState) => state.loginUser);    
    const [loginUserInternal, loginUserPropsInternal] = useLoginUserInternalMutation();
    const [loginUserOneSource, loginUserPropsOneSource] = useLoginUserOneSourceMutation();   
    
	const schema = yup.object({
		username: yup.string().required("This field is required."),
        password: yup.string().required("This field is required."),
	}).required();      

    const sp = new URLSearchParams(location.search);                
    if (userLoggingIn.username === "") {        
        if (sp.has("un") && sp.has("pw")) {
            userLoggingIn = {
                username: sp.get("un") ?? "",
                loginType: "USERNAME_PASSWORD",
                loginFrom: "/landing-page",
                idps: []
            };
            if (sp.has("returnUri")) {
                userLoggingIn.loginFrom = sp.get("returnUri") ?? "";
            }
        }            
    }    

    const defaultPassword = userLoggingIn.loginType === "USERNAME_PASSWORD" ? (sp.get("pw") ?? "") : "";
    const {register, handleSubmit, setFocus, getValues, formState: {errors}} = useForm<FormData>({	
        defaultValues: {username: userLoggingIn.username, password: defaultPassword },
		resolver: yupResolver(schema), mode: "onBlur"
	});    

    const onSubmit = handleSubmit(submitData => {
		const handleSubmitData = async (data: FormData) => {
			try 
            {
                if (userLoggingIn.loginType === "INTERNAL")
                {
                    await loginUserInternal({...data});     
                    landingPageApps.refetch();
                }
                else {                    
                    if (userLoggingIn.loginType === "USERNAME_PASSWORD") {
                        dispatch(updateLoginUser(userLoggingIn));
                    }    
                    await loginUserOneSource({...data});                    
                    landingPageApps.refetch();
                }           
			} catch (ex) {
                console.log(ex);
			}
		}		  	
		handleSubmitData(submitData);
	});

    useEffect( () => {
        const waitToLoginOneSource = async (data: any) => {
            const result: any = await loginUserOneSource({...data});
            if (result?.error !== null && result?.error !== undefined) {
                setIsOneSourceLoginFailed(true);
            }
            else {
                landingPageApps.refetch();    
            }
        }

        if (sp.has("un") && sp.has("pw")) {            
            const data: FormData = {
                username: sp.get("un") ?? "",
                password: sp.get("pw") ?? ""
            };
            if (userLoggingIn.loginType === "USERNAME_PASSWORD") {
                dispatch(updateLoginUser(userLoggingIn));
            }                
            waitToLoginOneSource(data);
        }
        else {
            setFocus("password");
        }
    }, [])

    if (!isOneSourceLoginFailed && sp.has("un") && sp.has("pw"))
    {
        return <StyledContent className="loading-screen">
            <div>
                Please wait while we log you in...
                <img src={loading} alt="loading"  />
            </div>
        </StyledContent>        
    }

    return (
        <StyledContent onSubmit={onSubmit}>
            {loginUserPropsInternal.isLoading || loginUserPropsOneSource.isLoading  ? <div>Logging in user {getValues("username")}....</div> : null}
            {loginUserPropsInternal.isError || loginUserPropsOneSource.isError ? <div role="alert" className="error">Invalid username or password</div> : null}
            <div className="login-form-text">
                <LoginTooltip />
                <input id="username" {...register("username")} readOnly placeholder="username@domain.com" className={errors.username && "email-password-error-input"} 
                        aria-invalid={!!errors.username} aria-describedby="username-error" />
						{ errors.username && <span id="username-error">{errors.username?.message}</span> }                
            </div>
            <div className="login-form-text">
                <label htmlFor="password">Password&nbsp;&nbsp;<span>(required)</span></label> 
                <input id="password" {...register("password")} type="password" className={errors.password && "email-password-error-input"} 
                        aria-invalid={!!errors.password} aria-describedby="password-error" aria-required="true" />
						{ errors.password && <span id="password-error">{errors.password?.message}</span> }                
            </div>
            <div>
                <button className="btn btn-primary custom-btn" type="submit">Login</button>     
            </div>
        </StyledContent>
    );
}

export default EmailPassword;