import styled from "styled-components";
import {mdiDotsVertical} from "@mdi/js";
import Icon from "@mdi/react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {updateActionMenu} from "../../redux/slices/context-menu";
import clsx from "clsx";

const StyledContent = styled.div `    
    .action-button {
        &.selected {
            background-color: #E7DFFA;
            cursor: pointer;
            border-radius: 50%;
            padding: 8px;            
        }
        &:hover {
            background-color: #E7DFFA;
            cursor: pointer;
            border-radius: 50%;
            padding: 8px;
        }
    }
`

export interface IActionDetailProps {
    uuid: string;
}

function ActionDetail(props: IActionDetailProps)
{   
    const {uuid} = props;
    const dispatch = useDispatch();   
    const actionMenu = useSelector((state: RootState) => state.contextMenu.actionMenu);    

    const onActionsClick = (event: any) => {
        dispatch(updateActionMenu({...actionMenu, 
            left: `${event.clientX}px`,
            top: `${event.clientY}px`,
            isModalOpen: true,
            uuid }));
    }

    return (
        <StyledContent onClick={onActionsClick}>
            <span className={`action-button 
                ${clsx({selected: uuid === actionMenu.uuid})}`}>
                <Icon path={mdiDotsVertical} size={1} color="#521DC7" />
            </span>                        
        </StyledContent>       
    )
}

export default ActionDetail;
