import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {updateUsername, updateLoginType, updateIdps} from "../redux/slices/login-user";
import {useLocation, useNavigate} from "react-router-dom";
import {useGetUserLoginOptionsMutation} from "../redux/services/user-administration";
import {useEffect} from "react";
import LoginTooltip from "./login-tooltip";

const StyledContent = styled.form `
    display: flex;
    flex-direction: column;
    .login-form-text {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        label {
            line-height: 20px;                        
            color: #232323;
        }
        input {
            border: 1px solid #6D6D6D;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 4px;
            text-indent: 8px;
            height: 32px;
            width: 360px;
            &:focus {				
                border:3px solid #094367;    			
                outline: 0;
            }                        
			&.username-error-input {
				border: 1px solid #BD0D22;
			}					
        }
        span {
			color: #BD0D22;
			font-weight: 400;	
		}
    }               
    .btn-primary.custom-btn {
        background-color: #094367;
        border-color: #094367;
        color: #FFFFFF;
        font-weight: 400;
        line-height: 19px;		
        width: 124px;            
        margin-top: 8px;
        :focus {
            box-shadow: 0 0 0 0.25rem #4C94FE;
        }
    }       
`
type FormData = {
	username: string;
};

function UsernameCheck()
{    
    const navigate = useNavigate();
    const location: any = useLocation();

	const schema = yup.object({
		username: yup.string().required("This field is required.")
	}).required();      

    const {register, handleSubmit, setFocus, getValues, formState: {errors}} = useForm<FormData>({		
		resolver: yupResolver(schema), mode: "onBlur"
	});    
    
    const [userToBeValidated, {isSuccess, isError, data}] = useGetUserLoginOptionsMutation();

    const dispatch = useDispatch();       
    const onSubmit = handleSubmit(submitData => {
		const handleSubmitData = async (data: FormData) => {
			try {
                userToBeValidated(data.username);                
			} catch (ex) {
                console.log(ex);
			}
		}		  	
		handleSubmitData(submitData);        
	});

    useEffect(() => {
        setFocus("username");        
        if (isSuccess) {
            dispatch(updateUsername(getValues("username")));  

            let loginType = data?.provider.toUpperCase();
            if (!loginType) loginType = "ONESOURCE";
            
            dispatch(updateUsername(getValues("username")));  
            dispatch(updateLoginType(loginType));  
            if (data?.idpOptions) dispatch(updateIdps(data?.idpOptions));
                  
            if (loginType=== "INTERNAL" || loginType === "ONESOURCE" ) {
                navigate(`password${location.search}`);
            }
            else {
                navigate(`idp${location.search}`);
            }        
        }    
    }, [isSuccess])

    return (
        <StyledContent onSubmit={onSubmit}>
            <div className="login-form-text">
                <LoginTooltip />
                <input id="username" {...register("username")} placeholder="Username or Email" 
                        aria-invalid={(isError || !!errors.username)} aria-describedby={!!errors.username ? "username-error1" : "username-error2"}
                        aria-required="true" className={(isError || errors.username) && "username-error-input"} />                    
						{ errors.username && <span id="username-error1">{errors.username?.message}</span> }                
                        { isError && <span id="username-error2">Unauthorized username</span> }                
            </div>
            <input type="submit" value="Next" className="btn btn-primary custom-btn" />
        </StyledContent>        
    );
}

export default UsernameCheck;