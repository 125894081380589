import React from "react";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {RootState} from "../redux/store";
import {ILoginUser} from "../redux/slices/login-user";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import LoginTooltip from "./login-tooltip";

const StyledContent = styled.form `
    display: flex;
    flex-direction: column;
    .error {
        color: red;
    }
    .login-form-text {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        label {
            line-height: 20px;                        
            color: #232323;
        }
        input,
        select {
            border: 1px solid #6D6D6D;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 4px;
            text-indent: 8px;
            height: 32px;
            width: 360px;
            &:focus {				
                border:3px solid #094367;    			
                outline: 0;
            }                        
			&.email-idp-error-input {
				border: 1px solid #BD0D22;
			}					
        }
        span {
			color: #BD0D22;
			font-weight: 400;	
		}
    }               
    .btn-primary.custom-btn {
        background-color: #094367;
        border-color: #094367;
        color: #FFFFFF;
        font-weight: 400;
        line-height: 19px;		
        width: 124px;            
        margin-top: 8px;
        :focus {
            box-shadow: 0 0 0 0.25rem #4C94FE;
        }
    }       
    .forgot-password {
        padding-top: 12px;
        a {
            text-decoration: none;
        }
    }             
`

type FormData = {
	username: string;
    idp: string;
};

function EmailIdp()
{
    const userLoggingIn:ILoginUser = useSelector((state: RootState) => state.loginUser);    

	const schema = yup.object({
		username: yup.string().required("This field is required."),
	}).required();      

    const [idpUrl, setIdpUrl] = useState("");

    const location: any = useLocation();   

    const {register, handleSubmit, setFocus, getValues, formState: {errors}} = useForm<FormData>({	
        defaultValues: {username: userLoggingIn.username},
		resolver: yupResolver(schema), mode: "onBlur"
	});    

    const idpSelected = (e : any) => {     
        
        const sp = new URLSearchParams(location.search);                
        if (sp.has("returnUri"))
        {
            setIdpUrl(e.target.value+"&ReturnUrl="+ sp.get("returnUri"));
        }
        else if (userLoggingIn.loginFrom)
        {
            setIdpUrl(e.target.value + "&ReturnUrl=" + encodeURIComponent(userLoggingIn.loginFrom));
        }
        else
        {
            setIdpUrl(e.target.value);
        }      
    }

    useEffect(() => {
        setFocus("idp");

        if (userLoggingIn && userLoggingIn.idps)
        {
            const sp = new URLSearchParams(location.search);                
            if (sp.has("returnUri"))
            {
                setIdpUrl(userLoggingIn.idps[0].redirectURL+"&ReturnUrl="+ sp.get("returnUri"));
            }
            else if (userLoggingIn.loginFrom)
            {
                setIdpUrl(userLoggingIn.idps[0].redirectURL + "&ReturnUrl=" + encodeURIComponent(userLoggingIn.loginFrom));
            }     
            else
            {
                setIdpUrl(userLoggingIn.idps[0].redirectURL);
            } 
        }
    }, [])

    return (
        <StyledContent>
            <div className="login-form-text">
                <LoginTooltip />
                <input id="username" {...register("username")} readOnly placeholder="username@domain.com" className={errors.username && "email-password-error-input"} 
                        aria-invalid={!!errors.username} aria-describedby="username-error" />
						{ errors.username && <span id="username-error">{errors.username?.message}</span> }                
            </div>
            <div className="login-form-text">
                <label htmlFor="idpSelect">Select Identity Provider</label> 
                <select id="idpSelect" {...register("idp")} onChange={idpSelected} >    
                    {userLoggingIn && userLoggingIn.idps ? userLoggingIn.idps.map(idp  => 
                       <option value={idp.redirectURL} key={idp.idpEntityId}>{idp.idpName}</option> ) : null}                   
                </select>
            </div>
            <div>
                <a className="btn btn-primary custom-btn" href={idpUrl} rel="noreferrer">
                Login</a>  
            </div>            
        </StyledContent>
    );
}

export default EmailIdp;