import { useEffect } from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useLogoutUserMutation} from "../redux/services/auth";
import rldatix from "../images/rldatix-logo.svg";

const StyledContent = styled.div `
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0px 32px;
    justify-content: space-between;
    .logo {
        width: 100px;
        height: 20px;
    }  
    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 11px;
        letter-spacing: 1px;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        background-color: #2D51E0;
        :hover {
            cursor: pointer;
        }
    }  
`

export interface IAuthStatus {
    userInitial: string;
}

function AuthStatus(props: IAuthStatus)
{
    const [logoutUser, {isSuccess}] = useLogoutUserMutation();    

    const onSignOut = (e: any) => {
        e.preventDefault();
        logoutUser();
        return false;
    }
    let navigate = useNavigate();    
    const {userInitial} = props;

    useEffect(() => {
        if (isSuccess) {
            navigate("/login");
        }    
    }, [isSuccess]);

    return (
        <StyledContent>
            <div className="logo">
                <img src={rldatix} alt="rldatix"  />
            </div>
            <div className='avatar' onClick={onSignOut}>
                {userInitial}
            </div>
        </StyledContent>
    );
}

export default AuthStatus;