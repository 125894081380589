import {RootState} from "../../redux/store";
import styled from "styled-components";
import Icon from "@mdi/react";
import {mdiArrowLeft, mdiAccountMultiple, mdiAccountPlus, mdiViewDashboard} from "@mdi/js";
import {useSelector, useDispatch} from "react-redux";
import {LeftNavigationOptions, updateSelectedOption} from "../../redux/slices/navigation";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";
import ExternalVarsUtil from "../../utils/utils";

const StyledContent = styled.div `
    display: flex;
    flex-direction: column;
    width: 312px;
    padding-left: 32px;
    padding-right: 48px;
    height: calc(100vh - 56px);
    a:link {
        text-decoration: none;        
    }
    .back-to-home {
        letter-spacing: 0px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding-top: 32px;
        padding-bottom: 8px;
        a {
            color: #676C75;
            display: flex;
            justify-content: space-between;
        }
    }    
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 8px;
    }
    nav {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        a {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;            
            color: #232323;            
            &.selected {
                color: #521DC7;
            }
            padding-bottom: 20px;
        }
        span {
            padding: 0 16px;
        }
    }
`

function LeftNavigation()
{
    const selectedMenuOption = useSelector((state: RootState) => state.navigation.selectedMenuOption);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClick = (option: LeftNavigationOptions) => {
        navigate("/" + option);
        dispatch(updateSelectedOption(option));
    }

    return (
        <StyledContent>
            <div className="back-to-home">
                <a href= {ExternalVarsUtil.getFullSubPath() + "/landing-page"}>
                    <span>Back to Home</span>
                    <Icon path={mdiArrowLeft} size={0.8} color="#232323" />
                </a>
            </div>
            <div className="title">
                Connected Authentication
            </div>
            <hr />
            <nav>
                <a href="#" className={clsx({selected: selectedMenuOption === LeftNavigationOptions.Customers})}
                    onClick={(e) => onClick(LeftNavigationOptions.Customers)}>
                    <span>
                        <Icon path={mdiAccountMultiple} size={0.7} color={selectedMenuOption === LeftNavigationOptions.Customers ? "#521DC7" : "#232323"} />
                    </span>                        
                    Customers                    
                </a>
                <a href="#" className={clsx({selected: selectedMenuOption === LeftNavigationOptions.IdentityProviders})}
                    onClick={(e) => onClick(LeftNavigationOptions.IdentityProviders)}>
                    <span>
                        <Icon path={mdiAccountMultiple} size={0.7} color={selectedMenuOption === LeftNavigationOptions.IdentityProviders ? "#521DC7" : "#232323"} />
                    </span>                    
                    Identity Providers                
                </a>
                <a href="#" className={clsx({selected: selectedMenuOption === LeftNavigationOptions.UserAdministration})}
                    onClick={(e) => onClick(LeftNavigationOptions.UserAdministration)}>
                    <span>
                        <Icon path={mdiAccountPlus} size={0.7} color={selectedMenuOption === LeftNavigationOptions.UserAdministration ? "#521DC7" : "#232323"} />
                    </span>                        
                    User Administration                    
                </a>
            </nav>
        </StyledContent>
    );
}

export default LeftNavigation;