import Icon from "@mdi/react";
import {useForm} from "react-hook-form";
import styled from "styled-components";
import Overlay from "../common/components/overlay";
import {mdiArrowRight} from "@mdi/js";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useLocation, useNavigate} from "react-router-dom";
import Toast, {ToastType} from "../common/components/toast";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {IIdp, useAddIdpMutation, useGetIdpsQuery, useUpdateIdpMutation} from "../redux/services/idp";
import CustomerSelect from "./customer-select";
import TextboxButton from "../common/components/file-upload-button";

const StyledContent = styled.div `
	padding: 24px;
	min-width: 400px;	
	background: white;
	z-index: 102;
	top: 0;
	right: 0;
	height: 100vh;
	position: absolute;	
	font-size: 14px;
    overflow: auto;

	.idp-breadcrumb {
		display: flex;
		align-content: center;		
		color: #6B778C;
		font-size: 15px;
		line-height: 17px;
		span {
			padding-left: 16px;
		}
		padding-bottom: 32px;
		:hover {
			cursor: pointer;
		}
	}
	.idp-title {
		color: #232323;
		font-weight: 800;
		font-size: 20px;
		line-height: 28px;
        padding-bottom: 20px;
	}
	.form-text-idp,
	.form-radio-idp {
		display: flex;
		flex-direction: column;
		font-weight: 600;
		line-height: 20px;
		color: #000000;
		label {
			display: flex;
			align-items: center;
		}
		span {
			color: #BD0D22;
            font-weight: 400;
		}
		input,
		textarea,
		select {
			height: 32px;
			text-indent: 8px;	
			&.idp-error-input {
				border: 1px solid #BD0D22;
			}					
			&:focus {						
    			border:3px solid #521DC7;    			
				outline: 0;
			}			
		}
		textarea {
			height: auto;
			text-indent: 0;
			padding: 4px 8px;
		}
		padding-bottom: 16px;
		p {
			font-weight: 400;
			line-height: 16px;
			color: #BD0D22;
		}
	}
	.form-radio-idp {
		label {
			display: flex;
			align-items: center;
		}		
		.radio-buttons {
			display: flex;
			label {
				width: 80px;
			}
			input {
				margin-right: 4px;
			}
		}
	}
    .btn-primary.custom-btn {
		margin-top: 8px;
	    background-color: #521DC7;
	    border-color: #521DC7;
        color: #FFFFFF;
		font-weight: 600;
		line-height: 19px;		
    }    
`

type FormProps = {
    customer: string;    
    idpName: string;
    idpDescription: string;    
    idpEntityId: string;
    domain: string;
	claimsIncluded: string;
	userUniqueIdMapping: string;
	usernameMapping: string;
	firstNameMapping: string;
	lastNameMapping: string;
	emailMapping: string;
	active: string;
	metaDataFileName: string;
	signRequest: string;
};

type MetaDataXmlType = {	
	metaDataFileName: string;	
	metaDataForm: any;
}

function UpdateIdps() {
	const {pathname} = useLocation();    
	const [opType, ] = pathname.split("/").reverse();
	const [metadataXml, setMetadataXml] = useState<MetaDataXmlType>();

	const {data: idps} = useGetIdpsQuery();
	const actionMenu = useSelector((state: RootState) => state.contextMenu.actionMenu);    
	const [newIdp, setNewIdp] = useState<IIdp|undefined|null>(undefined);

	const schema = yup.object({
		customer: yup.string().required("This field is required."),
        idpName: yup.string().required("This field is required.")
            .max(50, "Max length is 50 characters.")			
			.test("customerNumber-unique", "Customer and Idp Name must be unique.",
				function (value) {
					const notExisting = !idps?.some((idp: IIdp) => idp.customer===this.parent.customer && value===idp.idpName)					
					if (notExisting) return true;
					switch (opType) {
						case "create":
							return false;
						case "edit":
							const selectedIdp = idps?.find((idp: IIdp) => idp.customer===this.parent.customer && value===idp.idpName);
							if (selectedIdp?.uuid === actionMenu.uuid) {
								return true;
							}
							return false;							
					}
					return false;
				}),
		metaDataFileName: yup.string()
			.test("required", "IDP Metadata Xml Blob is required",
			function (value) {
				if (metadataXml?.metaDataFileName) {
					return true;
				}
				return false;				
			})
			.test("invalid-metadata-type", "IDP Metadata must be a valid XML File",
			function (value)
			{
				if (!metadataXml?.metaDataFileName.toLowerCase().endsWith(".xml") && metadataXml?.metaDataForm)
				{
					return false;
				}
				return true;
			}),
        idpDescription: yup.string().max(255, "Max length is 255 characters."),
        idpEntityId: yup.string().required("This field is required."),            
        domain: yup.string().required("This field is required.")
            .max(255, "Max length is 255 characters.")
	}).required();  

	let currentIdp: IIdp | undefined;
	if (actionMenu.uuid !== undefined && opType === "edit") {
		currentIdp = idps?.find((item: IIdp) => item.uuid === actionMenu.uuid);		
	} 

	useEffect(() => {
		if (actionMenu.uuid !== undefined && opType === "edit") {
			const foundIdp = idps?.find((item: IIdp) => item.uuid === actionMenu.uuid);
			setMetadataXml({metaDataFileName: foundIdp?.metaDataFileName ?? "", metaDataForm: undefined});
		}		
	}, [actionMenu.uuid])

		
	const {register, handleSubmit, getValues, formState: {errors}} = useForm<FormProps>({
		defaultValues: currentIdp,
		resolver: yupResolver(schema), mode: "onBlur"
	});
	const [addIdp, addMutation] = useAddIdpMutation();	
	const [updateIdp, updateMutation] = useUpdateIdpMutation();
	const navigate = useNavigate();	

  	const onSubmit = handleSubmit(submitData => {
		const handleAddIdp = async (data: FormProps) => {
			try {

				const idp = {...data, ...metadataXml};
				const formDataIdp = new FormData();   
				for (const [key, value] of Object.entries(idp)) {
					formDataIdp.append(key, value ?? "");
				}				
				if (opType === "create") {
					const createdIdp = await addIdp(formDataIdp).unwrap();
					setNewIdp(createdIdp);
				}
				else if (opType === "edit") {
					const updatedIdp = await updateIdp(formDataIdp).unwrap();		  
					setNewIdp(updatedIdp);
				}
			} catch {
				setNewIdp(null);
			}
		}		  	
		handleAddIdp(submitData);
	});

	const onClose = () => {
		navigate("..")
	}
  	
	const getTitle = () => {		
		switch (opType.toLowerCase()) {
			case "create":
				return "Create IDP";
			case "edit":
				return "Edit IDP";				
		}
		return `Create IDP`;		
	}
	const onUpload = (e : any) => {        
        let filename = e.target.files[0].name;
		setMetadataXml({metaDataFileName: filename, metaDataForm: e.target.files[0]});
    }

	return (
		<>
			<Overlay />
			{((addMutation.isUninitialized && opType === "create") || (updateMutation.isUninitialized && opType === "edit")) && <StyledContent>		
				<div className="idp-breadcrumb" onClick={onClose}>
					<Icon path={mdiArrowRight} size={0.8} color="#6B778C" />
					<span>Close</span>
				</div>
				<div className="idp-title">
					{getTitle()}
				</div>				
        		<form onSubmit={onSubmit}>
					<div className="form-text-idp">
						<label>Customer<span>&nbsp;*</span></label>
						<CustomerSelect register={register} errors={errors} />
					</div>
					<div className="form-text-idp">
						<label>Identity Provider Name<span>&nbsp;*</span></label>
						<input {...register("idpName")} className={errors.idpName && "idp-error-input"} />
						{ errors.idpName && <span>{errors.idpName?.message}</span> }
					</div>                    
					<div className="form-text-idp">
						<label>Identity Provider Description</label>
						<textarea rows={8} {...register("idpDescription")} className={errors.idpDescription && "idp-error-input"}/>
						{ errors.idpDescription && <span>{errors.idpDescription?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>IDP Entity ID<span>&nbsp;*</span></label>
						<input {...register("idpEntityId")} className={errors.idpEntityId && "idp-error-input"} />
						{ errors.idpEntityId && <span>{errors.idpEntityId?.message}</span> }
					</div>
					<div className="form-text-idp">
					 	<label>IDP Metadata<span>&nbsp;*</span></label>
						<TextboxButton onUpload={onUpload} textValue={metadataXml?.metaDataFileName ?? ""} className={errors.metaDataFileName && "idp-error-input"} />
						{ errors.metaDataFileName && <span>{errors.metaDataFileName?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>Domains<span>&nbsp;*</span></label>
						<input {...register("domain")} className={errors.domain && "idp-error-input"} />
						{ errors.domain && <span>{errors.domain?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>Mandatory Claims Included</label>
						<input {...register("claimsIncluded")} className={errors.claimsIncluded && "idp-error-input"} />
						{ errors.claimsIncluded && <span>{errors.claimsIncluded?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>User Unique ID Mapping</label>
						<input {...register("userUniqueIdMapping")} className={errors.userUniqueIdMapping && "idp-error-input"} />
						{ errors.userUniqueIdMapping && <span>{errors.userUniqueIdMapping?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>Username Mapping</label>
						<input {...register("usernameMapping")} className={errors.usernameMapping && "idp-error-input"} />
						{ errors.usernameMapping && <span>{errors.usernameMapping?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>First Name Mapping</label>
						<input {...register("firstNameMapping")} className={errors.firstNameMapping && "idp-error-input"} />
						{ errors.firstNameMapping && <span>{errors.firstNameMapping?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>Last Name Mapping</label>
						<input {...register("lastNameMapping")} className={errors.lastNameMapping && "idp-error-input"} />
						{ errors.lastNameMapping && <span>{errors.lastNameMapping?.message}</span> }
					</div>
					<div className="form-text-idp">
						<label>Email Mapping</label>
						<input {...register("emailMapping")} className={errors.emailMapping && "idp-error-input"} />
						{ errors.emailMapping && <span>{errors.emailMapping?.message}</span> }
					</div>
					<div className="form-radio-idp">
						<label>Sign the request</label>
						<div className="radio-buttons">
							<label htmlFor="Yes">
								<input {...register("signRequest", {required: true})} type="radio" name="signRequest" value="yes" id="Yes" />
								Yes
							</label>
							<label htmlFor="No">
								<input {...register("signRequest", {required: true})} type="radio" name="signRequest" defaultChecked  value="no" id="No" />
								No
							</label>
						</div>
					</div>	
					<div className="form-radio-idp">				
						<label>Status</label>
						<div className="radio-buttons">
							<label htmlFor="Active">
								<input {...register("active", {required: true})} type="radio" name="active" value="true" id="Active" />
								Active
							</label>
							<label htmlFor="Inactive">
								<input {...register("active", {required: true})} type="radio" name="active" defaultChecked value="false" id="Inactive" />
								Inactive
							</label>
						</div>
					</div>		
					<input type="submit" value={`${getTitle()} Account`} className="btn btn-primary custom-btn" />
        		</form>
		    </StyledContent>}
            {((addMutation.isSuccess && opType === "create") || (updateMutation.isSuccess && opType === "edit")) && <Toast open={true} type={ToastType.AlertSuccess} 
				onClose={onClose} title={`Idp Account Successfully ${opType === "create" ? "Created" : "Updated"}`}>
                <div>
                    The Idp Account for {newIdp?.idpName} has successfully been {opType === "create" ? "created" : "updated"}.
                </div>                
            </Toast>}
            {((addMutation.isError && opType === "create") || (updateMutation.isError && opType === "edit")) && <Toast open={true} type={ToastType.AlertError} 
				onClose={onClose} title={`Failed To ${opType === "create" ? "Create" : "Update"} Idp Account`}>
                <div>
                    Failed to {opType === "create" ? "create" : "update"} Idp Account for { getValues("idpName") }.
                </div>                
            </Toast>}
		</>		
  );
}

export default UpdateIdps;

