import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import useOnClickOutside from "../hooks/click-outside";
import {updateActionMenu} from "../../redux/slices/context-menu";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";

const StyledContent = styled.div `
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    position: fixed;
    background: white;
    > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 16px;
        min-height: 40px;        
        min-width: 88px;        
        &:hover {
            background-color: #E7DFFA;
        }
    }
`;

function ContextMenu()
{
    const ref = useRef<HTMLDivElement>(null);  
    const dispatch = useDispatch();   
    const actionMenu = useSelector((state: RootState) => state.contextMenu.actionMenu);    
    const navigate = useNavigate();    

    const {top, left, isModalOpen, menuItems} = actionMenu;
    const [newTop, setNewTop] = useState(top);

    useOnClickOutside(ref, () => dispatch(updateActionMenu({...actionMenu, isModalOpen: !actionMenu.isModalOpen})));
    const onClick = (item: string) => {        
        dispatch(updateActionMenu({...actionMenu, isModalOpen: false}));       
        switch (item) {
            case "Edit":
                navigate("edit");
                break;
        }
    }

    useEffect(() => {
        if (isModalOpen) {
            const value = ref.current?.clientHeight ?? 0;
            let topValue = parseInt(top.replace("px", ""));    
            const remainingHeight = window.innerHeight - topValue;    
            if (remainingHeight < (value + 20)) {
                topValue = topValue - (value);
            }
            setNewTop(`${topValue}px`);
        }
    }, [isModalOpen]);
    
    return (                
        isModalOpen ? <StyledContent style={{top: newTop, left: left}} ref={ref} >
            { menuItems.map((item: string, index: number) => {
                return <div key={index} onClick={() => {onClick(item)}} >{item}</div>
            }) }            
        </StyledContent> : <></>
    );
}

export default ContextMenu;