import {Outlet} from "react-router-dom";
import styled from "styled-components";
import LeftNavigation from "../common/components/left-navigation";
import {useWhoAmIQuery} from "../redux/services/auth";
import CustomerTable from "./customer-table";

const StyledContent = styled.div `
    background: #F0EDF6;
    display: flex;

    > .main-content {
        width: 100%;
        > div:first-child {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            padding-top: 34px;
            padding-bottom: 16px
        }
    }
    b {
        padding: 30px;        
    }
`

function CustomerAdministration() 
{
    const {isLoading, data} = useWhoAmIQuery();
    
    if (isLoading) {
        return <>Loading....</>;
    }

    if (data?.isAdmin) {
        return (
            <StyledContent>
                <LeftNavigation />
                <div className='main-content'>
                    <div>
                        Home / Customer Administration
                    </div>
                    <CustomerTable />
                </div>
                <Outlet />                        
            </StyledContent>
        );
    }
    if (data?.isAdmin === false) {
        return <StyledContent><b>Forbidden</b></StyledContent>
    }
    return <StyledContent>Error....</StyledContent>;
}

export default CustomerAdministration;