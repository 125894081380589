import styled from "styled-components";

const StyledContent = styled.div `
    position: fixed; /* Sit on top of the page content */    
    width: 100vw; /* Full width (cover the whole page) */
    height: 100vh; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 100; /* Specify a stack order in case you're using a different order for other elements */
`

function Overlay()
{
    return (
        <StyledContent />
    )
}

export default Overlay;