import Icon from "@mdi/react";
import styled from "styled-components";
import {mdiCheckboxMarkedCircle, mdiAlertCircle} from "@mdi/js";
import clsx from "clsx";

export enum ToastType {
    AlertSuccess,
    AlertError,
}

const StyledContent = styled.div `
    position: fixed; /* Sit on top of the page content */    
    width: 100vw; /* Full width (cover the whole page) */
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    .alert-success {
        color: black;
        background: #E3FCEF;
        border: 1px solid #006C2B;
    }
    .alert-error {
        background: #f7c0c6;
        border: 1px solid #BD0D22;
    }        

    > div:first-child {
        margin-top: 36px;
        
        box-sizing: border-box;
        border-radius: 4px;    
        display: flex;
        width: 800px;
        flex-direction: column;

        .toast-title {
            display: flex;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            > div:first-child {
                padding: 0 16px;
            }            
            padding-top: 16px;
            padding-bottom: 8px;
        }
        .toast-children {
            padding-left: 56px;
            padding-right: 24px;
        }

        > div:last-child {
            padding: 16px 56px;
            a {
                text-decoration: none;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    z-index: 200; /* Specify a stack order in case you're using a different order for other elements */
`

export interface IToastProps {
    open: boolean;
    type?: ToastType;
    title: string;      
    onClose?: any;
}


function Toast(props: React.PropsWithChildren<IToastProps>)
{
    const {open, type, title, onClose, children} = props;
    return (                
        <>
            {open && <StyledContent>
                <div className={
                    clsx({  "alert-success": type === ToastType.AlertSuccess,
                            "alert-error": type === ToastType.AlertError}) }>
                    <div className="toast-title">
                        <div>
                            {type === ToastType.AlertSuccess && <Icon path={ mdiCheckboxMarkedCircle } size={0.9} color="#006C2B" /> }
                            {type === ToastType.AlertError && <Icon path={ mdiAlertCircle } size={0.9} color="#BD0D22" /> }
                        </div>
                        <div>
                            { title }
                        </div>
                    </div>
                    <div className="toast-children">
                        {children}
                    </div>
                    <div>
                        <a href="#" onClick={onClose}>
                            Close
                        </a>            
                    </div>
                </div>                
            </StyledContent>}
        </>
    )
}

export default Toast;