import "./App.css";
import Login from "./login";
import AuthProvider from "./authentication/auth-provider";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import ProtectedPage from "./authentication/protected-page";
import UserAdministration from "./user-administration";
import CustomerAdministration from "./customer-administration";
import UpdateCustomer from "./customer-administration/update-customer";
import IdentityProviders from "./identity-providers";
import UpdateIdps from "./identity-providers/update-idps";
import LandingPage from "./landing-page";
import LogoutPage from "./logout-page";
import UsernameCheck from "./login/username-check";
import EmailPassword from "./login/email-password";
import EmailIdp from "./login/email-idp";
import ExternalVarsUtil from "./utils/utils";

function App() {    
    return (
        <AuthProvider>
            <Router basename={ExternalVarsUtil.getFullSubPath()}>
                <Routes>
                    <Route path="/landing-page"
                        element={
                            <ProtectedPage disableHeader={true}>
                                <LandingPage />
                            </ProtectedPage>
                        }>                    
                    </Route>
                    <Route path="/login" element={<Login />}>
                        <Route path="" element={<UsernameCheck />} />
                        <Route path="password" element={<EmailPassword />} />
                        <Route path="idp" element={<EmailIdp />} />
                    </Route>
                    <Route path="/logout"
                        element={
                        <LogoutPage />
                        }>                    
                    </Route>
                    <Route path="/customers"
                        element={
                            <ProtectedPage>
                                <CustomerAdministration />
                            </ProtectedPage>
                        }>
                        <Route path="create" element={<UpdateCustomer />} />
                        <Route path="edit" element={<UpdateCustomer />} />
                    </Route>
                    <Route path="/identity-providers"
                        element={
                            <ProtectedPage>
                                <IdentityProviders />
                            </ProtectedPage>
                        }>
                        <Route path="create" element={<UpdateIdps />} />
                        <Route path="edit" element={<UpdateIdps />} />
                    </Route>                
                    <Route path="/user-administration"
                        element={
                            <ProtectedPage>
                                <UserAdministration />
                            </ProtectedPage>
                        }>                    
                    </Route>
                    <Route path='/' element={<Navigate to="/landing-page" />} />
                </Routes>
            </Router>  
        </AuthProvider>
    );
}

export default App;
