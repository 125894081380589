import { ICustomer, useGetCustomersQuery } from "../redux/services/customer";


export interface ICustomerSelect
{
    register: any;
    errors: any
}

function CustomerSelect(props: ICustomerSelect)
{
    const {register, errors} = props;
    const {data: customers, isLoading, isUninitialized} = useGetCustomersQuery();

    if (isUninitialized && isLoading) {
        return <></>;
    }
    
    return (
        <>
            <select {...register("customer")} >
                {
                    customers?.map((item: ICustomer, index: number) => 
                        <option value={item.uuid} key={index}>{item.customerName}</option>)
                }
            </select>
            { errors.customer && <span>{errors.customer?.message}</span> }
        </>
    );
}

export default CustomerSelect;
