import styled from "styled-components";

const StyledContent = styled.div `
    display: flex;    
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;    
    margin-top: 20px;
    color: #483C66;

    .paging-option {
        display: flex;
        height: 32px;
        border: 1px solid #8E9AA6;
        box-sizing: border-box;
        border-radius: 3px;
        align-items: center;
        span {
            border-right: 1px solid #8E9AA6;
            padding: 8px;            
            :hover {
                cursor: pointer;
            }
            &.selected {
                background-color: #521DC7;
                color: white;
            }
            &.disabled {
                cursor: default;
                color: #6D6D6D;
                pointer-events: none;
            }
        }
        span:last-child {
            border-right: none;
        }
    }
`

export type PaginationType = {
    pageSize: number;
    selectedPage: number;
    itemCount: number;        
}

export interface IPaginationProps {
    pagination: PaginationType,
    setPagination: any,
}

function Pagination(props: IPaginationProps)
{
    const {pagination, setPagination} = props;
    const {pageSize, selectedPage, itemCount} = pagination;
    const totalPages = Math.ceil(itemCount/pageSize);

    const onPageClicked = (pageClicked: number) => {
        setPagination({...pagination, selectedPage: pageClicked});        
    }

    const createMiddlePaging = (pageClicked: number) => {
        const pages = [pageClicked-2, pageClicked-1, pageClicked, pageClicked+1, pageClicked+2]
            .filter(item => item > 1 && item < totalPages);
        if (pages.length < 5) {
            if (pages[0] === 2) {
                while (pages.length < 5 && pages[pages.length-1] < totalPages-1) {
                    pages.push(pages[pages.length-1] + 1);
                }                
            }
            else if (pages[pages.length-1] === totalPages-1)
            {
                while (pages.length < 5 && pages[0] > 1) {
                    pages.unshift(pages[0] - 1);
                }
            }
        }

        return pages.map((page: number, index: number) => 
            <span key={index} className={pageClicked === page ? "selected" : ""}
                onClick={() => onPageClicked(page)}>
                {page}
            </span>);
    }    

    const onChange = (e: any) => {
        setPagination({...pagination, pageSize: parseInt(e.target.value), selectedPage: 1});
    }
    const startItemCount = ((selectedPage-1)*pageSize)+1;
    const endItemCount = startItemCount+pageSize-1;

    return (
        <StyledContent>
            <div>
                Per Page 
                &nbsp;&nbsp;                
                <select name="pageSize" value={pageSize} onChange={onChange}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>                    
                </select>
                &nbsp;&nbsp;&nbsp;
                {startItemCount}-{endItemCount>itemCount ? itemCount : endItemCount} of {itemCount}
            </div>
            <div className="paging-option">
                <span className={selectedPage === 1 ? "disabled" : ""}
                    onClick={() => onPageClicked(selectedPage-1)}>
                    Previous
                </span>
                
                <span className={selectedPage === 1 ? "selected" : ""} 
                    onClick={() => onPageClicked(1)}>1</span>
                
                {selectedPage>4 && <span onClick={() => onPageClicked(selectedPage-3)}>...</span>}
                {
                    createMiddlePaging(selectedPage)
                }
                
                {selectedPage<(totalPages-3) && <span onClick={() => onPageClicked(selectedPage+3)}>...</span>}
                
                {totalPages>1 && <span className={selectedPage === totalPages ? "selected" : ""}
                    onClick={() => onPageClicked(totalPages)}>{totalPages}</span>}
                
                <span  className={selectedPage === totalPages ? "disabled" : ""}
                    onClick={() => onPageClicked(selectedPage+1)}>
                    Next
                </span>                
            </div>
        </StyledContent>    
    );
}

export default Pagination;