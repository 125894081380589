import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum LeftNavigationOptions {
    Customers = "customers",
    IdentityProviders = "identity-providers",
    UserAdministration = "user-administration",
}

export interface INavigation {
    selectedMenuOption: LeftNavigationOptions
}

export const NavigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        selectedMenuOption: LeftNavigationOptions.UserAdministration
    },
    reducers: {
        updateSelectedOption: (state: INavigation, action: PayloadAction<LeftNavigationOptions>) => {
            state.selectedMenuOption = action.payload
        },
        updateSelectedOptionWithUrl: (state: INavigation, action: PayloadAction<string>) => {
        switch(action.payload) {
            case "/customers": 
                state.selectedMenuOption = LeftNavigationOptions.Customers;
                break;
            case "/identity-providers":
                state.selectedMenuOption = LeftNavigationOptions.IdentityProviders;
                break;
            case "/user-administration":
                state.selectedMenuOption = LeftNavigationOptions.UserAdministration;
                break;
            default:
                state.selectedMenuOption = LeftNavigationOptions.UserAdministration;
        }        
    }
  },
})

export const {updateSelectedOption, updateSelectedOptionWithUrl} = NavigationSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = (state) => state.counter.value

export default NavigationSlice.reducer
