import {mdiHelpCircleOutline, mdiClose} from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import styled from "styled-components";

const StyledContent = styled.div `

    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;

    &:hover {
        cursor: pointer;
    }    

    .tooltiptext {       
        top: -20px; 
        background-color: #C8E0FF;        
        text-align: center;
        border-radius: 3px;
        padding: 0 12px 12px 12px;
        border:1px solid #93a7c0;
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        text-align: right;
        label {
            text-align: left;
        }
    }

    button {
        border: none;
        background-color: transparent;
    }

`

function LoginTooltip() : JSX.Element
{
    const [isTooltipShown, setIsTooltipShown] = useState(false);

    return (
        <StyledContent >
            <label htmlFor="username">Login ID</label>&nbsp;&nbsp;<span>(required)</span>&nbsp;&nbsp;
                <button onClick={() => setIsTooltipShown(true)} aria-label="More Info" id="buttonMoreInfo"> 
                    <Icon path={mdiHelpCircleOutline} size={0.65} color="#232323" />
                </button>
                &nbsp;&nbsp;
                {isTooltipShown && <span className="tooltiptext">
                    <button onClick={() => setIsTooltipShown(false)} aria-label="Close"> 
                        <Icon path={mdiClose} size={0.8} color="#232323" />
                    </button>
                    <label role="tooltip" aria-describedby="buttonMoreInfo">
                        This will either be your email address or your assigned User ID
                    </label>
                </span>}
        </StyledContent>
    );
}

export default LoginTooltip;