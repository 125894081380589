import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import navigationSlice from "./slices/navigation";
import contextMenuSlice from "./slices/context-menu";
import loginUserSlice from "./slices/login-user";
import {userAdministrationApi} from "./services/user-administration";
import {customerApi} from "./services/customer";
import {idpApi} from "./services/idp";
import {authApi} from "./services/auth";
import {landingPageAppApi} from "./services/landing-page-app";

export const store = configureStore({
  reducer: {
    navigation: navigationSlice,
    contextMenu: contextMenuSlice,
    loginUser: loginUserSlice,
    [userAdministrationApi.reducerPath]: userAdministrationApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,    
    [landingPageAppApi.reducerPath]: landingPageAppApi.reducer,    
    [idpApi.reducerPath]: idpApi.reducer,    
    [authApi.reducerPath]: authApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userAdministrationApi.middleware)
      .concat(customerApi.middleware)
      .concat(landingPageAppApi.middleware)
      .concat(idpApi.middleware)
      .concat(authApi.middleware)
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch