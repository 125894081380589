import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export enum LeftNavigationOptions {
    Customers = "customers",
    IdentityProviders = "identity-providers",
    UserAdministration = "user-administration",
}

export interface IActionMenu {
    top: string;
    left: string;
    menuItems: string[];
    isModalOpen: boolean;
    uuid: string;
}

export interface IContextMenu {
    actionMenu: IActionMenu
}

export const ContextMenuSlice = createSlice({
  name: 'context-menu',
  initialState: {
    actionMenu: {
        top: "0px",
        left: "0px",
        menuItems: ["Edit"],
        isModalOpen: false,     
        uuid: ""
    },

  },
  reducers: {
    updateActionMenu: (state: IContextMenu, action: PayloadAction<IActionMenu>) => {
        state.actionMenu = action.payload
    },
  },
})

export const {updateActionMenu} = ContextMenuSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = (state) => state.counter.value

export default ContextMenuSlice.reducer
