import React from "react";
import styled from "styled-components";
import TableLayout from "../common/components/table-layout";
import {DateTime} from "luxon";
import Icon from "@mdi/react";
import {mdiAccountMultiplePlus} from "@mdi/js";
import {useNavigate} from "react-router-dom";
import {IIdp, useGetIdpsQuery} from "../redux/services/idp";
import {ICustomer, useGetCustomersQuery} from "../redux/services/customer";
import ActionDetail from "../common/components/action-detail";

const StyledContent = styled.div `
    margin-right: 58px;
    padding: 28px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn-primary.custom-btn {
	    background-color: #C3CDF6;
	    border-color: #C3CDF6;
        color: #1B2E7A;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;        
        span {
            padding-right: 8px;
        }
    }    

    .idp-table-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 40px;
        font-size: 22px;
        font-weight: 800;
        line-height: 28px;
    }

    table {
        width: 100%;
        border-spacing: 0;
        border-radius: 2px;
        border: 1px solid #DBDBDB;

        tr {
            :last-child {
                td {
                    border-bottom: 0;                    
                }
            }
        }
        th,
        td {
            :last-child {
                text-align: center;
            }
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            height: 72px;    
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #DBDBDB;
        }
        th {
            font-size: 14px;    
            font-weight: 600;
            line-height: 9px;
        }
    }
`

  
function IdpTable() {
    const columns = React.useMemo(() => [
        {
            Header: "NAME",
            accessor: "idpName",
        },
        {
            Header: "DESCRIPTION",
            accessor: "idpDescription",
        },
        {
            Header: "CUSTOMER",
            accessor: "customerName",
        },
        {
            Header: "DOMAIN",
            accessor: "domain",
        },
        {
            Header: "UPDATED",
            accessor: (item: IIdp) => DateTime.fromISO(item.lastUpdated).toLocaleString(DateTime.DATETIME_SHORT)
        },
        {
            Header: "ACTIONS",
            accessor: (item: IIdp) => item.uuid,
            Cell: (props: any) => {
                return <ActionDetail uuid={props.cell.value}/>  
            },
            width: 80
        },                
      ], []);
  
    // Using a query hook automatically fetches data and returns query values
    const {data, error, isLoading, isError, isUninitialized} = useGetIdpsQuery();
    const customers = useGetCustomersQuery();

    const navigate = useNavigate();

    if (isUninitialized || customers.isUninitialized) {
        return <>
            "Initializing...";
        </>        
    }

    if (isLoading || customers.isLoading) {
        return <>
            "Loading...";
        </>        
    }

    if (isError || customers.isError) {
        if (error instanceof Error) {
            return <>
                { error.message }
            </>
        }
        if (customers.error instanceof Error) {
            return <>
                { customers.error.message }
            </>
        }
    }

    const onCreateUser = () => {
        navigate("create");
    }

    const compareItems = (a: IIdp, b: IIdp) => a.idpName.localeCompare(b.idpName, undefined, {sensitivity: "base"});
    const orderedData = data?.map((item: IIdp) => {        
        const currentItem = customers.data?.find((customer: ICustomer) => item.customer.toLowerCase() === customer.uuid.toLowerCase());
        return {...item, customerName: currentItem?.customerName || ""};
    }).sort(compareItems);        
    return (
        <StyledContent>          
            <div className="idp-table-title">
                <div>
                    Identity Providers
                </div>
                <button type="button" className="btn btn-primary custom-btn" onClick={onCreateUser}>
                    <span>Create Identity Provider</span>
                    <Icon path={mdiAccountMultiplePlus} size={0.8} color="#1B2E7A" />
                </button>
            </div>            
            <TableLayout columns={columns} data={orderedData} />            
        </StyledContent>
    )
}
  
export default IdpTable;
  