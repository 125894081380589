import {mdiMagnify} from "@mdi/js";
import Icon from "@mdi/react";
import { useRef } from "react";
import styled from "styled-components";

const StyledContent = styled.div `
    display:flex;    
    border:1px solid grey;

    &.idp-error-input {
        border: 1px solid #BD0D22;
    }

    /* I've used padding so you can see the edges of the elements. */

    input {
        /* Tell the input to use all the available space */
        flex-grow:2;
        /* And hide the input's outline, so the form looks like the outline */
        border:none;
        &.with-button.local {
            height: 31px;
        }        
    }

    input:focus {
        /* removing the input focus blue box. Put this on the form if you like. */
        outline: none;
        
        &.with-button.local {
            border: none;
        }
    }

    button {
        /* Just a little styling to make it pretty */
        display: flex;
        border: none;
        background: white;
        align-items: center;
    }
`

export interface IFileUploadButton
{
    onUpload: (e: any) => void;
    textValue: string;
    className?: string;
}

function FileUploadButton(props: IFileUploadButton)
{
    const {onUpload, textValue, className} = props;

    const ref = useRef<HTMLInputElement>(null);      
    const onClick = (e: any) => {
        e.preventDefault();
        ref.current?.click();        
    }

    const onChange = (e : any) => {        
        onUpload(e);
    }

    return (
        <StyledContent className={className}>
            <input id="fileUpload" type="file" accept=".xml" style={{display:"none"}} ref={ref} onChange={onChange} />
            <input type="text" readOnly={true} value={textValue} className="with-button local"/>
            <button onClick={onClick}>
                <Icon path={mdiMagnify} size={0.9} color="#521dc7" />
            </button>
        </StyledContent>
    );
}

export default FileUploadButton;
