import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ILoginUser {
    username: string;
    loginFrom: string;
    loginType: string;
    idps?: IIdpDetails[];
}

export interface IIdpDetails {
    idpName: string;
    idpEntityId: string;
    redirectURL: string;
}

export const LoginUserSlice = createSlice({
    name: 'login-user',
    initialState: {
        username: "",
        loginFrom: "",
        loginType: "",
        idps: []
    },
    reducers: {
        updateUsername: (state: ILoginUser, action: PayloadAction<string>) => {
            state.username = action.payload
        },
        updateLoginFrom: (state: ILoginUser, action: PayloadAction<string>) => {
            state.loginFrom = action.payload
        },
        updateLoginType: (state: ILoginUser, action: PayloadAction<string>) => {
            state.loginType = action.payload
        },
        updateIdps: (state: ILoginUser, action: PayloadAction<IIdpDetails[] | undefined>) => {
            state.idps = action.payload
        },
        updateLoginUser: (state: ILoginUser, action: PayloadAction<ILoginUser>) => {
            Object.keys(action.payload).forEach((key: string) => {                
                (state as any)[key] = (action.payload as any)[key];
            });            
        }
    },
})

export const {updateUsername, updateLoginFrom, updateLoginType, updateIdps, updateLoginUser} = LoginUserSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = (state) => state.counter.value

export default LoginUserSlice.reducer
