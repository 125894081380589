import {useTable, useRowSelect} from "react-table";
import ContextMenu from "./context-menu";
import IndeterminateCheckbox from "./indeterminate-checkbox";

function TableLayout(props: any) {
    // Use the state and functions returned from useTable to build your UI  
    const { columns, data } = props;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
    useRowSelect,
    hooks => {
        hooks.visibleColumns.push(cols => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // @ts-ignore          
          Header: ({getToggleAllRowsSelectedProps}) =>  (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          width: 30,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          // @ts-ignore
          Cell: ({row}) => (
            <div>           
              {/* @ts-ignore    */}
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />              
            </div>
          ),
        },
        ...cols,
        ])
    })  
    // Render the UI for your table
    // ContextMenu to be here rather than by cell because you only need one ref for the menu to appear
    return (
        <>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps({
                        style: { width: column.width != -1 ? column.width : "auto"  }
                        })}>{column.render('Header')}</th>
                    ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                    )
                })}
                </tbody>
            </table>      
            <ContextMenu />
        </>
    )
}
  
export default TableLayout;