import React from "react";
import {Outlet} from "react-router-dom";
import styled from "styled-components";
import building from "../images/building.png";
import rldatix from "../images/rldatix-logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useWhoAmIQuery} from "../redux/services/auth";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateLoginFrom} from "../redux/slices/login-user";
import {RootState} from "../redux/store";
import {updateSelectedOptionWithUrl} from "../redux/slices/navigation";
import {useGetLandingPageAppsQuery} from "../redux/services/landing-page-app";
import {mdiArrowLeft} from "@mdi/js";
import ExternalVarsUtil from "../utils/utils";
import Icon from "@mdi/react";

const StyledContent = styled.div `
    display: flex;
    font-weight: 400;
    font-size: 14px;
    .logo {
        img {
            height: 100vh;
        }        
    }
    .login-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 16px 24px;
        padding-left: 112px;
        h1 {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            padding-bottom: 16px;
        }
        img {
            height: 36px;
            width: 200px;
            align-self: flex-end;
        }
        > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            .login-title {
                padding-bottom: 24px;
            }
            .back-to-previous {
                padding-bottom: 60px;
                font-size: 13px;
                a {
                    text-decoration: none;
                    color: inherit;
                    height: 21px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
`

function Login() : JSX.Element
{   
    const whoAmI = useWhoAmIQuery();  
    const loginFrom = useSelector((state: RootState) => state.loginUser.loginFrom); 
    const landingPageApps = useGetLandingPageAppsQuery(); 

    const navigate = useNavigate();
    const dispatch = useDispatch();   
    const location: any = useLocation();  

    const sp = new URLSearchParams(location.search);       
    useEffect(() => {
        if (whoAmI.isSuccess && landingPageApps.isSuccess) {
            if (sp.has("returnUri"))
            {
                // return Uri should be based on subpath as well
                const redirectUrl = ExternalVarsUtil.getAppBaseUrl() + sp.get("returnUri");
                window.location.href =  redirectUrl;
            }
            else 
            {
                if((loginFrom == ExternalVarsUtil.getAppPath() + "/landing-page" || loginFrom =="/") && whoAmI?.data?.isAdmin === false)
                {
                    // Case for Force Redirect to OneSource Link
                    // NOTE: In future, this case will need to be altered if more services introduced
                    window.location.href = landingPageApps?.data?.[0]?.url;
                }   
                else
                {
                    window.location.href =  ExternalVarsUtil.getAppBaseUrl() + loginFrom;
                }
            }    
        }    
    }, [whoAmI.isSuccess, landingPageApps.isSuccess, loginFrom])
    useEffect(() => {
        const from =  (location.state?.from?.pathname ?? '/landing-page');
        dispatch(updateSelectedOptionWithUrl(from));
        dispatch(updateLoginFrom(ExternalVarsUtil.getAppPath() + from));        
    }, [])

    return (
        <StyledContent>
            <div className="logo">
                <img src={building} alt="" />
            </div>
            <div className="login-form">
                <img className="rldatix" src={rldatix} alt="RLDatix Logo" />
                <div>                    
                    {(!sp.has("un") && !sp.has("pw")) && <div className="login-title">
                        {(location.pathname.toLowerCase() === "/login/password" || location.pathname.toLowerCase() === "/login/idp") && <div className="back-to-previous">
                            <a href="#" onClick={() => navigate({ search: location.search, pathname: "/login" })}><Icon path={mdiArrowLeft} size={0.9} />&nbsp;<span>Back to Previous Screen</span></a>                            
                        </div>}
                        <h1>Login</h1>
                        <span>Please enter your details below</span>                    
                    </div>}
                    <Outlet />
                </div>                
            </div>
        </StyledContent>
    );                
}
  
export default Login;