import styled from "styled-components";
import rldatix from "../images/rldatix-logo.svg";
import admin from "../images/admin.png";
import building from "../images/building.png";
import onesource from "../images/one-source.png";
import {useNavigate} from "react-router-dom";
import {ILandingPageApp, useGetLandingPageAppsQuery} from "../redux/services/landing-page-app";
import {useWhoAmIQuery} from "../redux/services/auth";
import {useSearchParams} from "react-router-dom";
import {useLogoutUserMutation} from "../redux/services/auth";
import { useEffect, useState} from "react";
import ExternalVarsUtil from "../utils/utils";


const StyledContent = styled.div `
    display: flex;
    font-weight: 400;
    font-size: 14px;
    .logo {
        img {
            height: 100vh;
        }        
    }
    .logout-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 16px 24px;
        padding-left: 112px;
        h4 {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            padding-bottom: 16px;
        }
        img {
            height: 36px;
            width: 200px;
            align-self: flex-end;
        }
        > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            > span {
                padding-bottom: 24px;
            }
            height: 100%;
        }

    }
`

function LogoutPage()
{
    const navigate = useNavigate();    

    const [searchParams] = useSearchParams();

    const [logoutUser, {isSuccess, isError, isLoading}] = useLogoutUserMutation();

    const logoutTextBase = "You have successfully logged out.";
   
    const getLogoutMessage = () =>
    {
        let logoutMessage = "Logging out...";
        
        if (isSuccess)
        {
            if (searchParams.get("logoutMessage") != null && searchParams.get("logoutMessage") != "")
            {
                logoutMessage = decodeURI(String(searchParams.get("logoutMessage")));
            }
            else{
                logoutMessage = logoutTextBase;
            }
        }
        else if (isError)
        {
            logoutMessage = "Error logging out. There currently may be no logged in user.";
        }

        return logoutMessage;
    }

    useEffect(() => {
       logoutUser(); 
    }, [])


    return (
        <StyledContent>
            <div className="logo">
                <img src={building} alt="building" />
            </div>
            <div className="logout-form">
                <img className="rldatix" src={rldatix} alt="rldatix" />
                <div>                    
                    <h4>{getLogoutMessage()}</h4>
                    <span>Didn't mean to logout? <a href={ExternalVarsUtil.getFullSubPath() + "/login"}>Back to Login.</a></span>                    
                </div>                
            </div>
        </StyledContent>
    );                
}

export default LogoutPage;