import {Navigate, useLocation} from "react-router-dom";
import {useWhoAmIQuery} from "../redux/services/auth";
import AuthStatus from "./auth-status";

export interface IProtectedPage {
    disableHeader?: boolean;
}

function ProtectedPage(props: React.PropsWithChildren<IProtectedPage>) 
{
    const {disableHeader, children} = props;
    let location = useLocation();    
    const {isError, isLoading, isSuccess, data} = useWhoAmIQuery();

    if (isLoading) {
        return <>Loading....</>;
    }
    if (isError) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (isSuccess) {
        return (
            <>
                {!disableHeader && <AuthStatus userInitial={`${data?.firstName[0] ?? ""}${data?.lastName[0] ?? ""}`} />}
                {children}        
            </>);            
    }
    return <></>;
}

export default ProtectedPage;