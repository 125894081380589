import React from "react";
import styled from "styled-components";
import {useGetCustomersQuery, ICustomer} from "../redux/services/customer";
import TableLayout from "../common/components/table-layout";
import Icon from "@mdi/react";
import {mdiAccountMultiplePlus} from "@mdi/js";
import {DateTime} from "luxon";
import {useNavigate} from "react-router-dom";
import ActionDetail from "../common/components/action-detail";

const StyledContent = styled.div `
    margin-right: 58px;
    padding: 28px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn-primary.custom-btn {
	    background-color: #C3CDF6;
	    border-color: #C3CDF6;
        color: #1B2E7A;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;        
        span {
            padding-right: 8px;
        }
    }    

    .customer-table-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 40px;
        font-size: 22px;
        font-weight: 800;
        line-height: 28px;
    }

    table {
        width: 100%;
        border-spacing: 0;
        border-radius: 2px;
        border: 1px solid #DBDBDB;

        tr {
            :last-child {
                td {
                    border-bottom: 0;                    
                }
            }
        }
        th,
        td {
            :last-child {
                text-align: center;
            }
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            height: 72px;    
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #DBDBDB;
        }

        th {
            font-size: 14px;    
            font-weight: 600;
            line-height: 9px;
        }
    }
`
  
function CustomerTable() {

    const navigate = useNavigate();
    const columns = React.useMemo(() => [
        {
            Header: "CUSTOMER NAME",
            accessor: "customerName",            
        },
        {
            Header: "CUSTOMER DESCRIPTION",
            accessor: "customerDescription",
            width: -1
        },
        {
            Header: "CLIENT NUMBER",
            accessor: "customerNumber",
        },
        {
            Header: "LAST UPDATED",
            accessor: (item: ICustomer) => DateTime.fromISO(item.lastModified).toLocaleString(DateTime.DATETIME_SHORT),
            width: 180
        },
        {
            Header: "ACTIONS",         
            accessor: (item: ICustomer) => item.uuid,
            Cell: (props: any) => {
                return <ActionDetail uuid={props.cell.value}/>  
            },
            width: 80
        },                
      ], []);
  
    // Using a query hook automatically fetches data and returns query values
    const { data, error, isLoading } = useGetCustomersQuery();    

    if (isLoading) {
        return <>
            "Loading...";
        </>        
    }

    if (error) {
        if (error instanceof Error) {
            return <>
                { error.message }
            </>
        }
    }

    const onCreateCustomer = () => {
        navigate("create");    
    }

    const compareItems = (a: ICustomer, b: ICustomer) => b.lastModifiedSeconds - a.lastModifiedSeconds;
    const orderedData = data?.map((item: ICustomer) => item).sort(compareItems);      

    return (
        <StyledContent>                      
            <div className="customer-table-title">
                <div>
                    Customer Administration
                </div>                
                <button type="button" className="btn btn-primary custom-btn" onClick={onCreateCustomer}>
                    <span>Create Customer</span>
                    <Icon path={mdiAccountMultiplePlus} size={0.8} color="#1B2E7A" />
                </button>
            </div>
            <TableLayout columns={columns} data={orderedData} />               
        </StyledContent>
    )
}
  
export default CustomerTable;
  